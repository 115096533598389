/**
 * Application routes as template
 * @see interpolate function to inject params
 */
export enum Route {
    LOGIN = "/login",
    RESET_PASSWORD = "/reset-password",
    HOME = "/",
    CLASSROOMS_CREATE = "/classrooms/create",
    CLASSROOMS_EDIT = "/classrooms/edit",
    CLASSROOMS_LEARNERS = "/classrooms/learners",

    BRIEFS = "/briefs",
    MY_BRIEFS = "/briefs/my-briefs",
    BRIEFS_ASSIGNED = "/briefs/classroom-assigned",
    BRIEFS_COLLABORATE = "/briefs/collaborate",

    BRIEFS_EDIT = "/briefs/[id]/edit",
    BRIEFS_ASSIGN = "/briefs/[id]/assign",
    BRIEFS_GROUP_ASSIGN = "/briefs/[id]/groupAssign",
    BRIEFS_DETAILS = "/briefs/[id]",
    BRIEFS_CREATE = "/briefs/create",
    BRIEFS_EXPLORE = "/briefs/explore",

    TOPICS = "/workspaces/individual",
    TOPICS_DETAILS = "/workspaces/individual/[id]",
    PROFILE = "/profile",
    PROFILE_EDIT = "/profile/edit",
    ADMIN = "/admin",

    GDPR_DISCLAIMER = "/gdpr-settings",
    REGISTRATION = "/registration",

    WORKSPACES = "/workspaces",
    WORKSPACE_GROUP = "/workspaces/group",
    WORKSPACE_GROUP_DETAILS = "/workspaces/group/[id]/[[...slug]]",

    /**
     * Follow-ups list page
     */
    FOLLOW_UPS_LIST = "/follow-ups",
    FOLLOW_UPS_CREATE = "/follow-ups/create",
    FOLLOW_UPS_DETAILS = "/follow-ups/[followUpId]",
    FOLLOW_UPS_EDIT = "/follow-ups/[followUpId]/edit",

    RESOURCES = "/resources",

    PROFESSIONAL_SITUATION = "/professional-situations",
    PROFESSIONAL_SITUATION_CREATE = "/professional-situations/create",
    PROFESSIONAL_SITUATION_EDIT = "/professional-situations/[id]/edit",

    /**
     * Mission creation page
     */
    MISSION_CREATE = "/mission/create",
}

export default Route;
