import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Profile = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M12 21a9 9 0 100-18 9 9 0 000 18z"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M17 19v-1.54c0-.786-.281-1.538-.781-2.093-.5-.555-1.178-.867-1.886-.867H9.667c-.708 0-1.386.312-1.886.867A3.131 3.131 0 007 17.459V19M12 11.5a3 3 0 100-6 3 3 0 000 6z"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Profile;
