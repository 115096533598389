import { createGlobalStyle, css } from "styled-components";

import border from "./border";
import palette from "./palette";
import shape from "./shape";
import spacing from "./spacing";
import transitions from "./transitions";
import typography from "./typography";
import zIndex from "./zIndex";

const theme = {
    border,
    palette,
    shape,
    typography,
    spacing,
    transitions,
    zIndex,
    navbarHeight: 70,
    stylesheets: [
        "https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800,900|Source+Code+Pro:400|Roboto:300,400,500&display=swap",
    ],
};

export type Theme = typeof theme;

export const GlobalStyle = createGlobalStyle<{ admin?: boolean; storybook?: boolean }>`
    ${({ admin, storybook }) =>
        !admin &&
        css`
            body {
                background-color: ${({ theme }) => theme.palette.white.base};
                color: ${({ theme }) => theme.palette.black.base};
                height: 100vh;
                overflow: ${storybook ? "auto" : "hidden"};
            }

            #__next {
                position: relative;
                height: 100vh;
                overflow: auto;
            }
        `}

    *, *::before, *::after {
        box-sizing: border-box;
    }

    *::-webkit-scrollbar {
        width: 0.5rem;
        border-radius: 10rem;
        background: transparent;
    }

    *::-webkit-scrollbar-track {
        border-radius: 1rem;
    }

    *::-webkit-scrollbar-thumb {
        border: 0.2rem solid ${({ theme }) => theme.palette.grey.lighter};
        margin-right: 0.5rem;
        box-sizing: border-box;
        border-radius: 0.8rem;
    }
`;

export default theme;
