import isFunction from "lodash/isFunction";
import React, { FC, ReactNode, useContext, useMemo } from "react";
import { QueryResult } from "react-query";

import { usePersistedState } from "@sol/persistance";
import { IClassroom, IClassroomItem, useClassroomQuery } from "@sol/sdk";

export type ContextType = Omit<QueryResult<IClassroom, any>, "data" | "error"> & {
    activeClassroomId?: string;
    activeClassroom?: IClassroom;
    setActiveClassroom: (id?: string) => void;
};

const Context = React.createContext<ContextType | undefined>(undefined);

const { Provider } = Context;

export type ProviderRenderProp = (ctx: ContextType) => ReactNode;

export const ActiveClassroomProvider: FC<{
    classrooms?: IClassroomItem[];
    children: ReactNode | ProviderRenderProp;
}> = ({ classrooms, children }) => {
    const [id, setId] = usePersistedState<string>("activeClassroom");

    const activeClassroomId = useMemo(() => {
        if (classrooms && classrooms.length > 0) {
            if (id && classrooms.findIndex(classroom => classroom["@id"] === id) > -1) {
                return id;
            } else {
                return classrooms[0]["@id"];
            }
        }

        return;
    }, [classrooms, id]);

    const {
        data: activeClassroom,
        error,
        ...result
    } = useClassroomQuery({ classroom: activeClassroomId }, { enabled: !!activeClassroomId });

    if (error) {
        throw error;
    }

    const context = useMemo<ContextType>(() => {
        return {
            activeClassroomId,
            activeClassroom,
            setActiveClassroom: setId,
            ...result,
        };
    }, [activeClassroomId, activeClassroom, result, setId]);

    return <Provider value={context}>{children && isFunction(children) ? children(context) : children}</Provider>;
};

export const useActiveClassroom = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("ActiveClassroomProvider context is missing");
    }

    return context;
};

export default Context;
