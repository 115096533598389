/**
 * The application features set that can be enabled/disabled
 */
export enum Feature {
    /**
     * Login into the app
     */
    LOGIN = "login",
    /**
     * Register an account via mail link
     */
    REGISTER = "register",
    /**
     * Reset forgotten password
     */
    RESET_PASSWORD = "reset-password",
    /**
     * View and edit profile pages
     */
    PROFILE = "profile",
    /**
     * Validated skills list inside the profile page
     */
    PROFILE_SKILLS_VIEW = "profile-skills-view",
    /**
     * List classrooms inside profile page
     */
    PROFILE_CLASSROOMS_VIEW = "profile-classrooms-view",
    /**
     * Manage notifications inside profile and see them in the app header
     */
    NOTIFICATIONS = "notifications",
    /**
     *  Access homepage
     */
    HOMEPAGE = "homepage",
    /**
     *  Access classroom homepage cards
     */
    HOMEPAGE_CLASSROOM_CARD = "homepage-classroom-card",
    /**
     *  Access framework homepage cards
     */
    HOMEPAGE_FRAMEWORKS_CARD = "homepage-frameworks-card",
    /**
     *  Access metrics homepage cards
     */
    HOMEPAGE_METRICS_CARD = "homepage-metrics-card",
    /**
     *  Access learners homepage cards
     */
    HOMEPAGE_LEARNERS_CARD = "homepage-learners-card",
    /**
     *  Access briefs homepage cards
     */
    HOMEPAGE_BRIEFS_CARD = "homepage-briefs-card",
    /**
     * Access resources homepage cards
     */
    HOMEPAGE_RESOURCES_CARD = "homepage-resources-card",
    /**
     * Access Pedagogical Scenario homepage cards
     */
    HOMEPAGE_SCENARIO_CARD = "homepage-scenario-card",
    /**
     * List user classrooms, select an active classroom and view its details
     */
    SELECT_ACTIVE_CLASSROOM = "select-active-classroom",
    /**
     * View classroom metrics card
     */
    CLASSROOM_METRICS = "classroom-metrics",
    /**
     * Export classroom statistics
     */
    EXPORT_CLASSROOM_STATISTICS = "export-classroom-statistics",
    /**
     * View classroom metrics TODO
     */
    CLASSROOM_METRICS_TRAINER = "classroom-metrics-trainer",
    /**
     * View classroom leaners card
     */
    CLASSROOM_LEARNERS = "classroom-learners",
    /**
     * List briefs assigned to classroom
     */
    CLASSROOM_BRIEFS = "classroom-briefs",
    /**
     * Assign and Unassign brief for the classroom
     */
    CLASSROOM_BRIEFS_MANAGE = "classroom-briefs-manage",
    /**
     * TODO: define scope
     */
    CLASSROOM_BRIEFS_SUBMIT = "classroom-briefs-submit",
    /**
     * Update classroom properties and learners
     */
    CLASSROOM_UPDATE = "classroom-update",
    /**
     * Update classroom learners
     */
    CLASSROOM_UPDATE_LEARNERS = "classroom-update-learners",
    /**
     * Create a new classroom
     */
    CLASSROOM_CREATE = "classroom-create",
    /**
     * Join the classroom webinar
     */
    CLASSROOM_WEBINAR = "classroom-webinar",
    /**
     * Download classroom balance sheet
     */
    CLASSROOM_DOWNLOAD_BALANCE_SHEET = "classroom-download-balance-sheet",

    /**
     * Can delete comments
     */
    COMMENTS_EDIT = "comments:edit",

    /**
     * View FrameworksCard for the active classroom frameworks
     */
    HOMEPAGE_CLASSROOM_SCOPED_FRAMEWORKS = "homepage-classrooms-scoped-frameworks",

    // WIP: define more feature flags and scopes

    BRIEFS = "briefs",
    BRIEFS_EXPLORE = "briefs:explore",
    BRIEFS_CREATE = "briefs:create",
    BRIEFS_EDIT = "briefs:edit",
    BRIEFS_DELETE = "briefs:delete",
    BRIEFS_EDIT_COEDITORS = "briefs:edit:coeditors",
    BRIEFS_DUPLICATE = "briefs:duplicate",

    ADMIN = "admin",
    TOPICS = "topics",
    /**
     * Correct an individual work
     */
    TOPICS_WORK_EVAL = "topics-work-eval",
    /**
     * Submit an individual work
     */
    TOPICS_WORK_SUBMIT = "topics-workspace-submit",
    /**
     * Send messages in individual work
     */
    TOPICS_MESSAGE = "topics-message",
    /**
     * Access the group workspace
     */
    WORKSPACE_GROUP = "workspace-group",
    /**
     * Access the groups list (specific to learner)
     */
    WORKSPACE_GROUP_LIST = "workspace-group-list",
    /**
     * Correct group work
     */
    WORKSPACE_GROUP_CORRECTION = "workspace-group-correction",
    /**
     * Submit a work in a group workspace
     */
    WORKSPACE_GROUP_SUBMIT = "workspace-group-submit",
    /**
     * Manage a group workspace
     */
    WORKSPACE_GROUP_MANAGE = "workspace-group-manage",
    /**
     * Send message to a workspace group
     */
    WORKSPACE_GROUP_MESSAGE = "workspace-group-message",

    /**
     * Can view follow-ups
     */
    FOLLOW_UPS = "follow-ups",
    /**
     * Can select learner to list its follow ups
     */
    FOLLOW_UPS_LIST_LEARNER_SELECT = "follow-ups:list:learner-select",
    /**
     * Can create follow-up
     */
    FOLLOW_UPS_CREATE = "follow-ups:create",
    FOLLOW_UPS_CREATE_LEARNER = "follow-ups:create:learner",
    FOLLOW_UPS_CREATE_TRAINER = "follow-ups:create:trainer",
    FOLLOW_UPS_CREATE_TUTOR = "follow-ups:create:tutor",
    FOLLOW_UPS_CREATE_MANAGER = "follow-ups:create:manager",

    /**
     * Can edit follow up
     */
    FOLLOW_UPS_EDIT = "follow-ups:edit",
    FOLLOW_UPS_EDIT_LEARNER = "follow-ups:edit:learner",
    FOLLOW_UPS_EDIT_TRAINER = "follow-ups:edit:trainer",
    FOLLOW_UPS_EDIT_TUTOR = "follow-ups:edit:tutor",
    FOLLOW_UPS_EDIT_MANAGER = "follow-ups:edit:manager",

    /** Can receive notifications as learner */
    FOLLOW_UPS_NOTIFICATIONS_LEARNER = "follow-ups:notifications:learner",

    /**
     * Manage a learner's tutors
     */
    USERS_MANAGE_TUTORS = "users:manage-tutors",

    /**
     * Access resources space
     */
    RESOURCES = "resources",
    /**
     * Create a new resource
     */
    RESOURCE_CREATE = "resource-create",
    /**
     * Delete a resource from the active classroom
     */
    RESOURCE_DELETE = "resource-delete",
    /**
     * Update a resource
     */
    RESOURCE_UPDATE = "resource-udpate",
    /**
     * Access Professional Situation ressources
     */
    PROFESSIONAL_SITUATION = "professional-situation",
    /**
     * Create a professional situation
     */
    PROFESSIONAL_SITUATION_CREATE = "professional-situation-create",
    /**
     * Delete a professional situation
     */
    PROFESSIONAL_SITUATION_DELETE = "professional-situation-delete",
    /**
     * Edit a professional situation
     */
    PROFESSIONAL_SITUATION_UPDATE = "professional-situation-update",
    /**
     * Manage the classroom pedagogical scenario
     */
    PEDAGOGICAL_SCENARIO_MANAGE = "pedgogical-scenario-manage",
    /**
     * Delete educational brief
     */
    EDUCATIONAL_BRIEF_DELETE = "educational-brief-delete",

    /**
     * Create a mission
     */
    MISSION_CREATE = "mission-create",
}

/**
 * NOTE: This enum will be completed as the project grows.
 */

export default Feature;
