import { FC, useContext } from "react";

import AuthenticatedUserContext from "@sol/authentication/AuthenticatedUserContext";
import { useAuthenticatedUserClassrooms, useActiveClassroom } from "@sol/classrooms";
import { UserRole } from "@sol/sdk";

type Props = {
    role?: UserRole;
};

const ApplicationDataGate: FC<Props> = ({ children, role }) => {
    const classroomsCtx = useAuthenticatedUserClassrooms();
    const activeClassroomCtx = useActiveClassroom();
    const userCtx = useContext(AuthenticatedUserContext);

    const isAppLoading = classroomsCtx.isLoading || activeClassroomCtx.isLoading || userCtx.isLoading;

    if (!!role && isAppLoading) {
        return null;
    }

    return <>{children}</>;
};

export default ApplicationDataGate;
