import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Bell = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M17.333 8.6c0-1.485-.562-2.91-1.562-3.96A5.208 5.208 0 0012 3c-1.415 0-2.771.59-3.771 1.64A5.745 5.745 0 006.667 8.6C6.667 15.133 4 17 4 17h16s-2.667-1.867-2.667-8.4zM14 20a2.186 2.186 0 01-.846.732A2.588 2.588 0 0112 21c-.405 0-.803-.092-1.154-.268A2.186 2.186 0 0110 20"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Bell;
