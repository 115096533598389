// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import "../uikit/theme/styles/global.css";
import * as Sentry from "@sentry/browser";
import "codemirror/lib/codemirror.css";
import { Set } from "immutable";
import { decode as decodeJWT } from "jsonwebtoken";
import isFunction from "lodash/isFunction";
import App from "next/app";
import "react-multi-carousel/lib/styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { ReactNode, useState, useEffect, useMemo, Suspense } from "react";
import { I18nProvider } from "react-aria";
import { RouterProvider } from "react-aria-components";
import { Translation, useTranslation, I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import Validators from "redux-form-validators";
import { PersistGate } from "redux-persist/integration/react";
import HttpErrorBoundary from "src/components/HttpErrorBoundary";
import { ThemeProvider } from "styled-components";

import { RBACProvider, RouteAccessGuard, authenticatedFeatures } from "@sol/access-control";
import { AuthenticatedUserProvider } from "@sol/authentication";
import { ActiveClassroomProvider, ClassroomsProvider } from "@sol/classrooms";
import { Feature, FeaturesProvider } from "@sol/features";
import { NotificationsProvider } from "@sol/notifications";
import { StorageProvider, useStorage } from "@sol/persistance";
import { Route } from "@sol/routing";
import { UserRole, SDKProvider } from "@sol/sdk";

import ApplicationDataGate from "../components/ApplicationDataGate";
import GDPRGuard from "../components/GDPRDisclaimer/GDPRGuard";
import NoSSR from "../components/NoSSR";
import { BlockSkeleton } from "../components/skeletons";
import { AnalyticsProvider } from "../contexts/AnalyticsContext";
import { ModalProvider } from "../contexts/ModalContext";
import withReduxStore from "../hocs/withReduxStore";
import useLocale from "../hooks/useLocale";
import useToken from "../hooks/useToken";
import i18n from "../i18n";
import theme, { GlobalStyle } from "../uikit/theme";

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    release: process.env.VERSION,
});

// Setup redux-form-validators i18n bindings
// eslint-disable-next-line react/display-name
Validators.formatMessage = msg => <Translation>{t => t(msg.id, msg.values)}</Translation>;

const TokenConsumer = ({ children }: { children: (token?: string) => ReactNode }) => {
    const token: string = useToken();

    return children(token);
};

const getStorage = () => {
    if (typeof window !== "undefined") {
        return new Proxy(window.localStorage, {
            get: (storage: Storage, prop: string) => {
                if (prop === "setItem") {
                    return (key: string, value: string) => storage.setItem(`sol:${key}`, value);
                } else if (prop === "getItem") {
                    return (key: string) => storage.getItem(`sol:${key}`);
                } else if (prop === "removeItem") {
                    return (key: string) => storage.removeItem(`sol:${key}`);
                }

                return storage[prop];
            },
        });
    }
    return undefined;
};

const TUTOR_WITHOUT_LEARNERS_FEATURES = Set(authenticatedFeatures);
const USER_NO_CLASSROOM_FEATURES_WHITELIST = Set([...authenticatedFeatures, Feature.ADMIN]);
const ReactQueryDevtools = dynamic(() => import("react-query-devtools").then(mod => mod.ReactQueryDevtools));

const Devtools = () => {
    const storage = useStorage();
    const [activated, setActivated] = useState(false);
    const [t] = useTranslation();

    const devtoolsActivated = useMemo(() => {
        return activated || storage.getItem("devtools");
    }, [storage, activated]);

    useEffect(() => {
        if (window) {
            window.devtools = enable => setActivated(!!enable);

            return () => {
                delete window.devtools;
            };
        }
    }, [setActivated]);

    useEffect(() => {
        if (window?.devtools && devtoolsActivated) {
            window.devtools.translate = t;

            return () => {
                if (window?.devtools) {
                    delete window.devtools.translate;
                }
            };
        }
    }, [devtoolsActivated, t]);

    if (devtoolsActivated) {
        return <ReactQueryDevtools />;
    }

    return null;
};

const AriaI18nProvider = ({
    children,
}: {
    children: ReactNode | ((ctx: { locale: string; i18n: typeof i18n }) => ReactNode);
}) => {
    const [locale] = useLocale();

    useEffect(() => {
        if (i18n.language !== locale) {
            i18n.changeLanguage(locale);
        }
    }, [locale]);

    return (
        <I18nextProvider i18n={i18n}>
            <I18nProvider locale={locale}>{isFunction(children) ? children({ locale, i18n }) : children}</I18nProvider>
        </I18nextProvider>
    );
};

class MyApp extends App {
    componentDidCatch(error: Error, errorInfo: any) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });

            Sentry.captureException(error);
        });
    }

    getNextRouter() {
        return useRouter();
    }

    render() {
        const { Component, pageProps, store, persistor, router } = this.props;

        return (
            <>
                <Head>
                    <title>Simplonline</title>
                </Head>
                <NoSSR>
                    {/** DEBT there must be a way to factorize this code below. I see too much depth */}

                    <ThemeProvider theme={theme}>
                        <Suspense fallback={<BlockSkeleton />}>
                            <ReduxProvider store={store}>
                                <AriaI18nProvider>
                                    {({ locale }) => (
                                        <PersistGate persistor={persistor}>
                                            <GDPRGuard>
                                                <AnalyticsProvider>
                                                    <StorageProvider storage={getStorage()}>
                                                        <TokenConsumer>
                                                            {token => {
                                                                const decodedToken = token && decodeJWT(token);

                                                                const role =
                                                                    decodedToken?.roles?.length > 0
                                                                        ? (decodedToken.roles[0] as UserRole)
                                                                        : undefined;

                                                                return (
                                                                    <SDKProvider
                                                                        baseURL={process.env.API_URL || ""}
                                                                        token={token}
                                                                        locale={locale}
                                                                    >
                                                                        <HttpErrorBoundary>
                                                                            <AuthenticatedUserProvider token={token}>
                                                                                {({ user }) => {
                                                                                    const isTutor =
                                                                                        role === UserRole.TUTOR;

                                                                                    return (
                                                                                        <ClassroomsProvider
                                                                                            isAuthenticated={!!token}
                                                                                        >
                                                                                            {({ classrooms }) => (
                                                                                                <ActiveClassroomProvider
                                                                                                    classrooms={
                                                                                                        isTutor
                                                                                                            ? undefined
                                                                                                            : classrooms
                                                                                                    }
                                                                                                >
                                                                                                    <ApplicationDataGate
                                                                                                        role={role}
                                                                                                    >
                                                                                                        <RBACProvider
                                                                                                            role={role}
                                                                                                        >
                                                                                                            {features => {
                                                                                                                const userFeatures =
                                                                                                                    isTutor &&
                                                                                                                    (user
                                                                                                                        ?.learnersAsTutor
                                                                                                                        .length ??
                                                                                                                        0) ===
                                                                                                                        0
                                                                                                                        ? TUTOR_WITHOUT_LEARNERS_FEATURES
                                                                                                                        : features;

                                                                                                                return (
                                                                                                                    <FeaturesProvider
                                                                                                                        // Access to admin for managers with no classroom
                                                                                                                        // Access to profile for users with no classroom
                                                                                                                        features={
                                                                                                                            classrooms &&
                                                                                                                            classrooms.length ===
                                                                                                                                0
                                                                                                                                ? Set.intersect(
                                                                                                                                      [
                                                                                                                                          userFeatures,
                                                                                                                                          USER_NO_CLASSROOM_FEATURES_WHITELIST,
                                                                                                                                      ],
                                                                                                                                  )
                                                                                                                                : userFeatures
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <RouteAccessGuard>
                                                                                                                            <NotificationsProvider>
                                                                                                                                <ModalProvider>
                                                                                                                                    <GlobalStyle
                                                                                                                                        admin={
                                                                                                                                            router.pathname ===
                                                                                                                                            Route.ADMIN
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    <RouterProvider
                                                                                                                                        navigate={
                                                                                                                                            this.getNextRouter()
                                                                                                                                                .push
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <Component
                                                                                                                                            {...pageProps}
                                                                                                                                        />
                                                                                                                                    </RouterProvider>
                                                                                                                                    <Devtools />
                                                                                                                                </ModalProvider>
                                                                                                                            </NotificationsProvider>
                                                                                                                        </RouteAccessGuard>
                                                                                                                    </FeaturesProvider>
                                                                                                                );
                                                                                                            }}
                                                                                                        </RBACProvider>
                                                                                                    </ApplicationDataGate>
                                                                                                </ActiveClassroomProvider>
                                                                                            )}
                                                                                        </ClassroomsProvider>
                                                                                    );
                                                                                }}
                                                                            </AuthenticatedUserProvider>
                                                                        </HttpErrorBoundary>
                                                                    </SDKProvider>
                                                                );
                                                            }}
                                                        </TokenConsumer>
                                                    </StorageProvider>
                                                </AnalyticsProvider>
                                            </GDPRGuard>
                                        </PersistGate>
                                    )}
                                </AriaI18nProvider>
                            </ReduxProvider>
                        </Suspense>
                    </ThemeProvider>
                </NoSSR>

                <style jsx global>{`
                    body {
                        margin: 0;
                    }
                `}</style>
            </>
        );
    }
}

MyApp.displayName = "MyApp";

export default withReduxStore(MyApp);
