import { Set } from "immutable";
import isFunction from "lodash/isFunction";
import React, { useContext, ReactNode } from "react";

import Feature from "./Feature";

const defaultContext = Set<Feature>(); // Empty features set

const FeaturesContext = React.createContext<Set<Feature>>(defaultContext);

const { Provider } = FeaturesContext;

export type FeaturesProviderRenderProp = (features: Set<Feature>) => ReactNode;

type Props = {
    /**
     * The features set that are considered to be enabled
     */
    features: Set<Feature>;
    children: ReactNode | FeaturesProviderRenderProp;
};

/**
 * Enable features in part of the app
 */
export const FeaturesProvider = ({ features, children }: Props) => {
    return <Provider value={features}>{children && isFunction(children) ? children(features) : children}</Provider>;
};

/**
 * Retrieve the enabled features set
 */
export const useFeatures = () => useContext(FeaturesContext);

export default FeaturesContext;
