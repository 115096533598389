import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Clipboard = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M15 6h1.5c.398 0 .78.164 1.06.456.282.291.44.687.44 1.1v10.888c0 .413-.158.809-.44 1.1A1.474 1.474 0 0116.5 20h-9c-.398 0-.78-.164-1.06-.456a1.585 1.585 0 01-.44-1.1V7.556c0-.413.158-.809.44-1.1A1.473 1.473 0 017.5 6H9"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M14.25 4h-4.5a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zM9 11h6m-6 4h3.75"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Clipboard;
