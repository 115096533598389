import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Admin = (props: SvgIconProps) => {
    const { stroke, fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M18.004,1.98L5.996,1.98C5.049,1.98 4.281,2.815 4.281,3.65L4.281,20.35C4.281,21.185 5.049,22.02 5.996,22.02L18.004,22.02C18.951,22.02 19.719,21.185 19.719,20.35L19.719,3.65C19.719,2.815 18.951,1.98 18.004,1.98Z"
                stroke="black"
                strokeWidth="2"
                className={stroke}
            />
            <path
                d="M15.431,12C15.431,11.539 15.057,11.165 14.596,11.165L9.404,11.165C8.943,11.165 8.569,11.539 8.569,12C8.569,12.461 8.943,12.835 9.404,12.835L14.596,12.835C15.057,12.835 15.431,12.461 15.431,12Z"
                fill="black"
                className={fill}
            />
            <path
                d="M15.431,18.68C15.431,18.219 15.057,17.845 14.596,17.845L9.404,17.845C8.943,17.845 8.569,18.219 8.569,18.68C8.569,19.141 8.943,19.515 9.404,19.515L14.596,19.515C15.057,19.515 15.431,19.141 15.431,18.68Z"
                fill="black"
                className={fill}
            />
            <path
                d="M4.742,15.34L19.448,15.34"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                className={stroke}
            />
            <path
                d="M4.616,8.66L19.64,8.66"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                className={stroke}
            />
            <path
                d="M15.431,5.32C15.431,4.859 15.057,4.485 14.596,4.485L9.404,4.485C8.943,4.485 8.569,4.859 8.569,5.32C8.569,5.781 8.943,6.155 9.404,6.155L14.596,6.155C15.057,6.155 15.431,5.781 15.431,5.32Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default Admin;
