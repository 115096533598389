import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Statistics = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M19 8L12.9545 14.0455L9.77273 10.8636L5 15.6364"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M15.1818 8H18.9999V11.8182"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Statistics;
