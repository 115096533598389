import isFunction from "lodash/isFunction";
import React, { FC, useMemo, useContext, ReactNode } from "react";
import { QueryResult } from "react-query";

import { IClassroomList, IClassroomItem } from "@sol/sdk";
import { useClassrooms } from "@sol/sdk/classrooms/useClassrooms";

export type ContextType = Omit<QueryResult<IClassroomList, any>, "data" | "error"> & {
    classrooms?: IClassroomItem[];
};

const Context = React.createContext<ContextType | undefined>(undefined);

const { Provider } = Context;

export type ProviderRenderProp = (ctx: ContextType) => ReactNode;

export const ClassroomsProvider: FC<{ isAuthenticated: boolean; children: ReactNode | ProviderRenderProp }> = ({
    isAuthenticated,
    children,
}) => {
    const { data, error, ...result } = useClassrooms({
        filters: { restricted: true },
        enabled: isAuthenticated,
    });

    if (error) {
        throw error;
    }

    const context = useMemo<ContextType>(() => {
        const classrooms = data?.["hydra:member"];

        return {
            classrooms,
            ...result,
        };
    }, [data, result]);

    return <Provider value={context}>{children && isFunction(children) ? children(context) : children}</Provider>;
};

export const useAuthenticatedUserClassrooms = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error("ClassroomsProvider context is missing");
    }

    return context;
};

export default Context;
