import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";

import { Feature, useCheckFeatureFlag, useFeatures } from "@sol/features";
import { Route } from "@sol/routing";
import { EnumDictionary } from "@sol/utils";

// WIP: Feature sets should be well defined before filling this dictionnary
const ACCESS_CONTROL_LIST: EnumDictionary<Route, Feature[]> = {
    // CLASSROOMS
    [Route.HOME]: [Feature.HOMEPAGE],
    [Route.CLASSROOMS_CREATE]: [Feature.CLASSROOM_CREATE],
    [Route.CLASSROOMS_EDIT]: [Feature.CLASSROOM_UPDATE],
    [Route.CLASSROOMS_LEARNERS]: [Feature.CLASSROOM_UPDATE_LEARNERS],

    // BRIEFS
    [Route.BRIEFS]: [Feature.BRIEFS],
    [Route.BRIEFS_ASSIGNED]: [Feature.CLASSROOM_BRIEFS],
    [Route.MY_BRIEFS]: [Feature.BRIEFS_CREATE, Feature.TOPICS_WORK_EVAL, Feature.WORKSPACE_GROUP_CORRECTION],
    [Route.BRIEFS_COLLABORATE]: [Feature.BRIEFS_CREATE],
    [Route.BRIEFS_EXPLORE]: [Feature.BRIEFS_EXPLORE],
    [Route.ADMIN]: [Feature.ADMIN],

    [Route.BRIEFS_CREATE]: [Feature.BRIEFS_CREATE],
    // TODO: think a new permission system for scoped route access (ex: ErrorBoundary + restricted scope)
    // or we don't care as the page is not reachable through user experience (no such a link is displayed)
    [Route.BRIEFS_EDIT]: [Feature.BRIEFS_CREATE],
    [Route.BRIEFS_DETAILS]: [Feature.BRIEFS],
    [Route.BRIEFS_ASSIGN]: [Feature.BRIEFS_CREATE],
    [Route.BRIEFS_GROUP_ASSIGN]: [Feature.BRIEFS_CREATE],

    // USER
    [Route.PROFILE]: [Feature.PROFILE],
    [Route.PROFILE_EDIT]: [Feature.PROFILE],

    // PUBLIC
    [Route.GDPR_DISCLAIMER]: [],

    // ANONYMOUS
    [Route.LOGIN]: [Feature.LOGIN],
    [Route.RESET_PASSWORD]: [Feature.LOGIN],
    [Route.REGISTRATION]: [Feature.REGISTER],

    [Route.WORKSPACES]: [Feature.TOPICS, Feature.WORKSPACE_GROUP],

    // TOPICS
    [Route.TOPICS]: [Feature.TOPICS],
    [Route.TOPICS_DETAILS]: [Feature.TOPICS],

    // WORKSPACE GROUP
    [Route.WORKSPACE_GROUP]: [Feature.WORKSPACE_GROUP],
    [Route.WORKSPACE_GROUP_DETAILS]: [Feature.WORKSPACE_GROUP],

    // FOLLOW UPS
    [Route.FOLLOW_UPS_LIST]: [Feature.FOLLOW_UPS],
    [Route.FOLLOW_UPS_CREATE]: [Feature.FOLLOW_UPS_CREATE],
    [Route.FOLLOW_UPS_DETAILS]: [Feature.FOLLOW_UPS],
    [Route.FOLLOW_UPS_EDIT]: [Feature.FOLLOW_UPS_EDIT],

    // RESOURCES
    [Route.RESOURCES]: [Feature.RESOURCES],

    // PROFESSIONAL SITUATIONS
    [Route.PROFESSIONAL_SITUATION_CREATE]: [Feature.PROFESSIONAL_SITUATION_CREATE],
    [Route.PROFESSIONAL_SITUATION_EDIT]: [Feature.PROFESSIONAL_SITUATION_UPDATE],
    [Route.PROFESSIONAL_SITUATION]: [Feature.PROFESSIONAL_SITUATION],

    // MISSIONS
    [Route.MISSION_CREATE]: [Feature.MISSION_CREATE],
};

type Props = { placeholder?: ReactNode; children: ReactNode };

export const RouteAccessGuard = ({ children }: Props) => {
    const features = useFeatures();
    const checkFeatureFlag = useCheckFeatureFlag();
    const { route, replace } = useRouter();

    const defaultPage = useMemo(() => {
        if (checkFeatureFlag(Feature.HOMEPAGE)) {
            return Route.HOME;
        } else if (checkFeatureFlag(Feature.ADMIN)) {
            return Route.ADMIN;
        } else if (checkFeatureFlag(Feature.PROFILE)) {
            return Route.PROFILE;
        }

        return Route.LOGIN;
    }, [checkFeatureFlag]);

    const allow = useMemo(() => {
        const acl: Feature[] = ACCESS_CONTROL_LIST[route as Route] || [];

        // Allow by default
        if (acl.length === 0) {
            return true;
        }

        // Allow only if one of our features match acl
        return acl.findIndex(feature => features.has(feature)) > -1;
    }, [route, features]);

    if (allow) {
        return children;
    }

    replace(defaultPage);

    return null;
};

export default RouteAccessGuard;
