import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Frameworks = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M18.8456 14.6567V9.05557C18.8453 8.81001 18.7733 8.56883 18.6368 8.35624C18.5003 8.14364 18.304 7.9671 18.0678 7.84432L12.6234 5.04374C12.3869 4.92084 12.1186 4.85614 11.8456 4.85614C11.5725 4.85614 11.3043 4.92084 11.0678 5.04374L5.62336 7.84432C5.38712 7.9671 5.1909 8.14364 5.05438 8.35624C4.91787 8.56883 4.84586 8.81001 4.84558 9.05557V14.6567C4.84586 14.9023 4.91787 15.1434 5.05438 15.356C5.1909 15.5686 5.38712 15.7452 5.62336 15.868L11.0678 18.6685C11.3043 18.7914 11.5725 18.8561 11.8456 18.8561C12.1186 18.8561 12.3869 18.7914 12.6234 18.6685L18.0678 15.868C18.304 15.7452 18.5003 15.5686 18.6368 15.356C18.7733 15.1434 18.8453 14.9023 18.8456 14.6567Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Frameworks;
