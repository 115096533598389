import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const SelfEvaluation = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M11 7.5l-1.875 3L8 9.643M11 13.5l-3 3m0-3l3 3"
                stroke="black"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <mask id="prefix__a" fill="#fff">
                <rect x={4} y={4} width={16} height={16} rx={1} />
            </mask>
            <rect
                x={4}
                y={4}
                width={16}
                height={16}
                rx={1}
                stroke="black"
                strokeWidth={4}
                strokeLinejoin="round"
                mask="url(#prefix__a)"
                className={stroke}
            />
            <path stroke="black" strokeWidth={2} strokeLinecap="round" d="M14 9h2M14 15h2" className={stroke} />
        </SvgIcon>
    );
};

export default SelfEvaluation;
