import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Classroom = (props: SvgIconProps) => {
    const { stroke } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M15.4 5H8.4C7.6268 5 7 5.6268 7 6.4V17.6C7 18.3732 7.6268 19 8.4 19H15.4C16.1732 19 16.8 18.3732 16.8 17.6V6.4C16.8 5.6268 16.1732 5 15.4 5Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
            <path
                d="M14 12H14.007"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={stroke}
            />
        </SvgIcon>
    );
};

export default Classroom;
