import { AxiosPromise } from "axios";
import { useCallback, useMemo } from "react";
import { QueryConfig, QueryKey, useQuery } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { IClassroomResource } from "../classrooms/IClassroomResource";
import { IFrameworkResource } from "../frameworks/IFrameworkResource";
import { ILanguageResource } from "../languages/ILanguageResource";
import { ISkillLevelResource } from "../skillLevels/ISkillLevelResource";
import { ITagResource } from "../tags/ITagResource";
import { IPaginated } from "../types/IPaginated";
import { IUserResource } from "../users/IUserResource";
import {
    mapRelationalFilter,
    mapRelationalValue,
    OneOrManyFilter,
    RelationalFilter,
    RelationalValue,
} from "../utils/filters";
import { serialize } from "../utils/url";
import { ISkillResource } from "./../skills/ISkillResource";
import { AssignationType } from "./AssignationType";
import { BriefStatus } from "./BriefStatus";
import { CorrectionStatus } from "./CorrectionStatus";
import { IBriefList } from "./IBriefList";

export type BriefOrderBy = "updatedAt" | "createdAt" | "title";
export type BriefOrder = "asc" | "desc";

type Params<TResult = unknown, TError = unknown> = {
    filters?: {
        activeClassroom?: RelationalValue<IClassroomResource>;
        classrooms?: RelationalFilter<IClassroomResource>;
        correctionStatus?: OneOrManyFilter<CorrectionStatus>;
        coeditors?: RelationalFilter<IUserResource>;
        createdByConnectedUser?: boolean;
        createdBy?: RelationalFilter<IUserResource>;
        status?: OneOrManyFilter<BriefStatus>;
        assignationType?: OneOrManyFilter<AssignationType>;
        title?: string | null;

        assignedClassroom?: RelationalValue<IClassroomResource>;
        assignedLearner?: RelationalValue<IUserResource>;

        topicClassrooms?: RelationalFilter<IClassroomResource>;
        topicLearners?: RelationalFilter<IUserResource>;

        frameworks?: RelationalFilter<IFrameworkResource>;
        skills?: RelationalFilter<ISkillResource>;
        skillLevels?: RelationalFilter<ISkillLevelResource>;

        tags?: RelationalFilter<ITagResource>;
        languages?: RelationalFilter<ILanguageResource>;
    };
    queryKey?: QueryKey;
    orderBy?: BriefOrderBy;
    order?: BriefOrder;
    pagination?: Required<Pick<IPaginated, "page" | "perPage">>;
} & Omit<QueryConfig<TResult, TError>, "queryKey" | "queryFn">;

// TODO: move to utils and handle error handling
const unwrapAPIResult = async <T>(promise: AxiosPromise<T>) => {
    const res = await promise;

    return res.data;
};

const mapFilters = (filters: Params["filters"]) => {
    const {
        activeClassroom,
        classrooms,
        correctionStatus,
        topicClassrooms,
        topicLearners,
        coeditors,
        createdByConnectedUser,
        createdBy,
        status,
        assignationType,
        title,
        assignedClassroom,
        assignedLearner,
        frameworks,
        skills,
        skillLevels,
        tags,
        languages,
    } = filters || {};
    return {
        "activeClassroom.uuid": mapRelationalValue(activeClassroom),
        "classrooms.uuid": mapRelationalFilter(classrooms),
        "coeditors.uuid": mapRelationalFilter(coeditors),
        "createdBy.uuid": mapRelationalFilter(createdBy),

        createdByConnectedUser,
        status,
        assignationType,
        correctionStatus,
        title,

        "assigned.classroom.uuid": mapRelationalValue(assignedClassroom),
        "assigned.learner.uuid": mapRelationalValue(assignedLearner),

        /** Seems no more in use across the app: perhaps can we delete these 2 filters in the API */
        "topics.classroom.uuid": mapRelationalFilter(topicClassrooms),
        "topics.learner.uuid": mapRelationalFilter(topicLearners),

        "skillLevels.skill.frameworks.uuid": mapRelationalFilter(frameworks),
        "skillLevels.skill.uuid": mapRelationalFilter(skills),
        "skillLevels.uuid": mapRelationalFilter(skillLevels),

        "tags.uuid": mapRelationalFilter(tags),
        "language.uuid": mapRelationalFilter(languages),
    };
};

export const useBriefs = ({
    filters,
    queryKey,
    order = "desc",
    orderBy = "createdAt",
    pagination,
    ...options
}: Params<IBriefList>) => {
    // Retrieve the API axios instance to make requests
    const api = useApi();

    const url = useMemo(
        () =>
            `/briefs${serialize(
                {
                    // Disable pagination on the query if not set
                    ...(!pagination ? { pagination: false } : pagination),
                    [`order[${orderBy}]`]: order,
                    ...mapFilters(filters),
                },
                true,
            )}`,
        [filters, pagination, order, orderBy],
    );

    const queryFn = useCallback(() => unwrapAPIResult(api.get<IBriefList>(url)), [api, url]);

    return useQuery<IBriefList, any>({
        queryKey: queryKey || url,
        queryFn,
        config: options,
    });
};
