import React from "react";

import SvgIcon, { classes } from "./SvgIcon";
import { SvgIconProps } from "./types";

const Logo = (props: SvgIconProps) => {
    const { fill } = classes;

    return (
        <SvgIcon {...props}>
            <path
                d="M20.335,3.905C22.609,6.179 23.76,8.909 23.76,12.066C23.76,15.25 22.609,17.953 20.335,20.227C18.061,22.502 15.278,23.652 11.987,23.652C8.722,23.652 5.939,22.502 3.665,20.227C1.391,17.953 0.24,15.223 0.24,12.066C0.24,8.909 1.391,6.206 3.665,3.905C5.939,1.631 8.722,0.48 11.987,0.48C15.278,0.48 18.061,1.631 20.335,3.905ZM11.987,2.968C9.578,2.968 7.491,3.851 5.725,5.617C3.959,7.383 3.076,9.524 3.076,12.066C3.076,14.608 3.959,16.775 5.725,18.541C7.491,20.307 9.578,21.19 11.987,21.19C14.395,21.19 16.482,20.307 18.248,18.541C20.014,16.775 20.897,14.635 20.897,12.066C20.897,9.524 20.014,7.383 18.248,5.617C16.482,3.851 14.395,2.968 11.987,2.968Z"
                fill="black"
                className={fill}
            />
            <path
                d="M13.726,7.25L10.274,7.25L10.274,10.701L13.726,10.701L13.726,7.25ZM13.726,13.404L10.274,13.404L10.274,16.856L13.726,16.856L13.726,13.404Z"
                fill="black"
                className={fill}
            />
        </SvgIcon>
    );
};

export default Logo;
